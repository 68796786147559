import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Styles
import Style from "./MapBox.module.css";

const MapBox = () => {
  return (
    <Container className={Style.mapContainer}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3040.514665796543!2d18.18906691658815!3d40.35311117937254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13442e57cc5a3cf9%3A0x15608420d7020f72!2sVia%20Pitagora%2C%204%2C%2073100%20Lecce%20LE%2C%20Italia!5e0!3m2!1sit!2sae!4v1669300155113!5m2!1sit!2sae"
        width="100%"
        height="450"
        loading="lazy"
      ></iframe>
    </Container>
  );
};

export default MapBox;
