import React from "react";

// Libraries
import { Container, Row, Col, Button } from "@hybris-software/ui-kit";
import { useNavigate } from "react-router-dom";

// Icons
import { MdWorkOutline } from "react-icons/md";

// Styles
import Style from "./HeroSection.module.css";

const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <div className={Style.heroSection}>
      <div className={Style.heroContainer}>
        <Container className={Style.heroContent}>
          <h1>Società tra Professionisti</h1>
          <p className={Style.subTitle}>
            Società tra Professionisti Marzo Castelluzzo Verdicchia. Esperti in
            Consulenza a Lecce. Consulenza amministrativa, Consulenza
            societaria, Consulenza tributaria.
          </p>
          <Button
            className={Style.ctaButton}
            onClick={() => {
              navigate("/services");
            }}
          >
            <MdWorkOutline />
            <p>I nostri servizi</p>
          </Button>
        </Container>
      </div>
      <Container>
        <div className={Style.quotationBox}>
          <Row>
            <Col lg={6} md={12} className={Style.aboutUs}>
              <h4>Su di Noi</h4>
              <p>
                Lo Studio MCV, è una realtà che aggrega
                professionisti provenienti da esperienze, diverse e di alto
                profilo, in importanti realtà imprenditoriali e professionali,
                in grado di offrire un servizio di consulenza affidabile ed
                efficace orientato al business aziendale, trasferendo alle
                organizzazioni vantaggi evidenti e duraturi.
              </p>
            </Col>
            <Col className={Style.aboutUsImg} lg={6}></Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default HeroSection;
