import React from "react";

// Components
import { Container } from "@hybris-software/ui-kit";

// Assets
import FundamentalsBg from "../../../Assets/images/bg-fundamentals.jpg";

// Icons
import Mission from "../../../Assets/icons/mission.png";
import Vision from "../../../Assets/icons/vision.png";
import Values from "../../../Assets/icons/values.png";

// Styles
import Style from "./Fundamentals.module.css";

const data = [
  {
    title: "Mission",
    icon: Mission,
    text: "Individuiamo problemi aziendali e forniamo strumenti per affrontare sfide del mercato con programmazione, gestione e controllo.",
  },
  {
    title: "Vision",
    icon: Vision,
    text: "Aiutiamo le aziende a superare le sfide del mercato, identificando problemi, fornendo strumenti e metodi di gestione e controllo.",
  },
  {
    title: "Valori",
    icon: Values,
    text: "Integrità, competenza, successo clienti. Qualità, trasparenza, collaborazione, innovazione.",
  },
];

const Fundamentals = () => {
  return (
    <Container
      style={{
        backgroundImage: `url(${FundamentalsBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      className={Style.mainContainer}
    >
      <div className={Style.innerContainer}>
        {data.map((card, index) => {
          return <Card key={index} {...card} />;
        })}
      </div>
    </Container>
  );
};

const Card = ({ title, icon, text }) => {
  return (
    <div className={Style.card}>
      <div className={Style.icon}>
        <img src={icon} />
      </div>
      <div className={Style.title}>{title}</div>
      <div className={Style.text}>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Fundamentals;
