// Libraries
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { generateApiClient, ApiProvider } from "@hybris-software/use-query";
import { ThemeProvider } from "@hybris-software/ui-kit";

// Data
import config from "./Data/config";
import { routeComponents } from "./Data/routes";

// Views
import NotFoundView from "./Views/Generic/NotFoundView";

// Utils
import ScrollRestoration from "./Utils/ScrollRestoration";

// Styles
import Theme from './Assets/css/Theme.module.css'

function App() {
  const apiClient = generateApiClient({
    baseUrl: config.API_BASE_URL,
    authorizationHeader: "Authorization",
    authorizationPrefix: "Bearer",
  });

  return (
    <BrowserRouter>
      <ThemeProvider theme={{
        container: {
          containerClassName: Theme.containerStyle,
        },
        row: {
          rowClassName: Theme.rowStyle,
          columnGap: {
            horizontal: {
              xs: 50,
              sm: 50,
              md: 50,
              lg: 50,
              xl: 50,
              xxl: 50,
            },
            vertical: {
              xs: 20,
              sm: 20,
              md: 20,
              lg: 20,
              xl: 20,
              xxl: 20,
            }
          },
        },
        col: {
          columnStyle: Theme.columnStyle,
        },
        inputField: {
          baseClassName: Theme.inputFieldStyle,
          errorClassName: Theme.inputFieldErrorStyle,
          successClassName: Theme.inputFieldSuccessStyle,
          labelClassName: Theme.inputFieldLabelStyle,
          successIconVisibility: false,
          errorIconVisibility: false,
        },
        button: {
          buttonClassName: Theme.buttonCommon,
          buttonDisabledClassName: Theme.buttonCommonDisabled,
          loader: <div className={Theme.ldsRing}><div></div><div></div><div></div><div></div></div>,
        },
      }} >
        <ApiProvider apiClient={apiClient}>
          <ScrollRestoration>
            <Routes>
              {routeComponents}
              <Route path="*" element={<NotFoundView />} />
            </Routes>
          </ScrollRestoration>
        </ApiProvider>
      </ThemeProvider>
    </BrowserRouter >
  );
}

export default App;
