import React from "react";

// Libraries
import { Container, Row, Col } from "@hybris-software/ui-kit";
import { Link } from "react-router-dom";

// Images
import AboutUsImage from "../../../Assets/images/bg-column-blue.png";

// Icons
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

// Styles
import Style from "./AboutUsRow.module.css";

// Data
import { paths } from "../../../Data/routes";

const AboutUsRow = ({ link = true }) => {
  return (
    <Container>
      <div className={Style.mainContainer}>
        <Row>
          <Col
            md={6}
            sm={12}
            style={{ marginBottom: "unset", marginBlock: "auto" }}
          >
            <div className={Style.leftCol}>
              <img src={AboutUsImage} alt="" />
            </div>
          </Col>
          <Col md={6} sm={12} style={{ marginBlock: "auto" }}>
            <div className={Style.rightCol}>
              <div className={Style.header}>
                <h6>In cosa siamo Esperti</h6>
                <h3>Perché scegliere Noi</h3>
              </div>
              <p className={Style.mainDescription}>
                Consulenza aziendale, societaria e tributaria a Lecce
              </p>
              <p className={Style.subDescription}>
                Lo Studio MCV è un'azienda di commercialisti ed esperti
                contabili specializzati in consulenza fiscale e tributaria
                compresa la tenuta della contabilità e l'assistenza
                contrattuale. Offriamo servizi qualificati e ad alto valore
                aggiunto, compresa la contabilità e l'assistenza legale. Con
                oltre 20 anni di esperienza, possiamo risolvere i tuoi dubbi e problemi
                fiscali in modo semplice e comprensibile. Richiedi una prima
                consulenza gratuita, sia in persona che online.
              </p>
              {link && (
                <Link to={paths.main.aboutUs}>
                  <h6>
                    Scopri di più <HiOutlineArrowNarrowRight />
                  </h6>
                </Link>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default AboutUsRow;
