import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Styles
import Style from "./HeroSection.module.css";

const HeroSection = () => {
  return (
    <div className={Style.heroSection}>
      <div className={Style.heroContainer}>
        <Container className={Style.heroContent}>
          <h1>Su di Noi</h1>
          <p className={Style.subTitle}>
            Il nostro team è composto da esperti in materia legale, contabile ed
            amministrativa. La nostra mission è quella di offrire ai nostri
            clienti un servizio completo e di qualità.
          </p>
        </Container>
      </div>
    </div>
  );
};

export default HeroSection;
