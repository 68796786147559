import React, { useEffect } from "react";

// Components
import Loader from "../../../Components/UIKit/Loader/Loader";
import HeroSection from "../../../Components/Advanced/HeroSectionAboutUs/HeroSection";
import AboutUsRow from "../../../Components/Advanced/AboutUsRow/AboutUsRow";
import Fundamentals from "../../../Components/Advanced/Fundamentals/Fundamentals";
import WhyChooseUs from "../../../Components/Advanced/WhyChooseUs/WhyChooseUs";
import Faq from "../../../Components/Advanced/Faq/Faq";
import ContactForm from "../../../Components/Advanced/ContactForm/ContactForm";
import TeamSlider from "../../../Components/Advanced/TeamSlider/TeamSlider";

// Styles
import Style from "./AboutUsView.module.css";

const AboutUsView = () => {
  useEffect(() => {
    document.title = "Studio MCV | Su di Noi";
  }, []);
  return (
    <>
      <Loader />
      <section className={Style.heroSection}>
        <HeroSection />
      </section>
      <section className={Style.aboutUs}>
        <AboutUsRow link={false} />
      </section>
      <section className={Style.fundamentals}>
        <Fundamentals />
      </section>
      <section className={Style.whyUs}>
        <WhyChooseUs />
      </section>
      <section className={Style.faq}>
        <TeamSlider />
      </section>
      <section className={Style.faq}>
        <Faq />
      </section>
      <section className={Style.faq}>
        <ContactForm description="Compila il form di contatto e richiedi la tua consulenza, i nostri professionisti ti ricontatteranno entro 24 ore" />
      </section>
    </>
  );
};

export default AboutUsView;
