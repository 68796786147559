import React, { useState } from "react";

// Libraires
import { Container } from "@hybris-software/ui-kit";

// Styles
import Style from "./Template.module.css";

// Data
const elements = [
  {
    reference: "first",
    title: "Consulenza Aziendale",
    content:
      "La nostra società offre consulenza completa e supporto nella pianificazione ed esecuzione di operazioni societarie straordinarie, come fusioni, acquisizioni, scissioni e riorganizzazioni. Grazie alla nostra vasta esperienza, siamo in grado di assistere i nostri clienti in tutte le fasi del ciclo di vita aziendale, fornendo sia supporto continuativo che interventi mirati in situazioni più complesse. I nostri consulenti si impegnano a stabilire una relazione di fiducia con il cliente, mettendo a disposizione la loro competenza e professionalità per offrire consulenza aziendale e societaria. In un mercato sempre più competitivo, la consulenza aziendale diventa sempre più cruciale per aiutare le imprese a raggiungere i loro obiettivi e ottenere successo. Lavorando in collaborazione con il cliente e il suo staff, i nostri consulenti esaminano l'azienda, il mercato e gli obiettivi per fornire le strategie più adatte alle necessità del cliente e sfruttare appieno le potenzialità dell'impresa. Il nostro obiettivo è fornire ai vertici aziendali gli strumenti e le informazioni necessarie per guidare l'impresa e massimizzare i profitti. I nostri consulenti adottano un approccio globale nella definizione della strategia aziendale, compresa la gestione del rapporto con i clienti e il sistema di marketing, prima di passare alla pianificazione finanziaria ed economica.",
  },
  {
    reference: "second",
    title: "Consulenza Fiscale",
    content:
      "Il nostro studio di consulenza fiscale si occupa di tutti gli aspetti della tassazione, sia per le imposte dirette che indirette, delle società, imprese individuali, enti ed associazioni. Oltre alla semplice trasmissione delle dichiarazioni fiscali, i nostri esperti offrono assistenza costante per le questioni più complesse e rappresentanza legale in caso di contenzioso tributario. I nostri commercialisti, specializzati in fiscalità, diritto tributario, pianificazione fiscale e compliance, assistono imprese e persone fisiche, fornendo servizi di consulenza fiscale e consigli per ridurre il carico fiscale. Il loro primo obiettivo è l'elaborazione della strategia fiscale dell'impresa o del gruppo societario, comunicata in modo chiaro e trasparente ai clienti. Offriamo inoltre aggiornamenti periodici in materia fiscale e rappresentanza legale in caso di contenzioso tributario, sfruttando la nostra esperienza ultra-quarantennale nel settore per fornire una consulenza fiscale approfondita e continuativa ai nostri clienti.",
  },
  // {
  //   reference: "third",
  //   title: "Consulenza Legale",
  //   content:
  //     "Il nostro Studio Legale fornisce assistenza legale e consulenza in varie questioni giudiziali e stragiudiziali riguardanti società, contratti, affitti, condomini, recupero crediti, successioni e diritto di famiglia. Ci impegniamo a offrire servizi altamente specializzati e personalizzati, garantendo competenza, professionalità e riservatezza. In particolare, ci occupiamo del recupero di crediti sia per le aziende che per i privati, utilizzando tutti i mezzi legali necessari per proteggere i diritti dei creditori e risolvere le controversie in modo rapido ed efficace. Nel settore del diritto di famiglia, siamo impegnati a trovare la soluzione migliore per tutelare i diritti dei nostri clienti e dei minori coinvolti. Inoltre, offriamo consulenza ai condomini e amministratori di condominio per risolvere eventuali controversie in ambito condominiale. Siamo orgogliosi di fornire servizi legali di alta qualità ai nostri clienti e di assistere in ogni fase del procedimento giudiziale o stragiudiziale.",
  // },
  {
    reference: "four",
    title: "Consulenza Strategica",
    content:
      "Il nostro obiettivo come consulenti strategici è quello di aiutare gli imprenditori a trovare soluzioni personalizzate e pratiche ai loro problemi aziendali. Ci impegniamo a lavorare a stretto contatto con loro in tutte le fasi del processo, offrendo un sostegno operativo per il raggiungimento dei loro obiettivi. Il nostro approccio non si limita alla presentazione di soluzioni, ma piuttosto ad una consulenza a 360 gradi che si estende dalla contabilità alla gestione dei processi di cambiamento. Offriamo la nostra esperienza professionale in comunicazione, problem-solving e gestione del cambiamento per aiutare le imprese a diventare più forti e competitive sui loro mercati di riferimento. La nostra consulenza strategica si basa sulla creazione di una linea guida coerente che coinvolge tutti i livelli dell'azienda. Inoltre, i nostri consulenti si dedicano direttamente alla realizzazione delle strategie e dei cambiamenti organizzativi, utilizzando le loro competenze e l'esperienza professionale acquisita in oltre quarant'anni di attività. La nostra attenzione alle necessità specifiche dei clienti è il nostro punto di forza distintivo, che ci ha permesso di affermarci come uno dei migliori studi di consulenza strategica.",
  },
  {
    reference: "five",
    title: "Consulenza Societaria",
    content:
      "Offriamo consulenza per aiutare i clienti nella costituzione e gestione delle loro società. Siamo in grado di assistere i clienti in tutte le fasi del processo di costituzione o ristrutturazione, nonché nella gestione ordinaria e straordinaria delle società. Il nostro team di consulenti legali ha competenze solide nelle materie civilistiche e commerciali. Riteniamo fondamentale comprendere correttamente il business dei clienti in ambito giuridico, analizzando a fondo le norme del diritto privato. Garantiamo il necessario supporto giuridico nella formalizzazione delle delibere societarie, nella predisposizione di strumenti di governance e controllo, nella cessione e acquisizione di aziende e di partecipazioni, nella definizione di atti e contratti, e in tutti gli adempimenti afferenti la gestione societaria. Offriamo anche analisi dei costi e dei benefici associati ad eventuali processi giudiziari, al fine di aiutare i clienti a prendere decisioni informate e consapevoli.",
  },
  {
    reference: "six",
    title: "Revisione Legale",
    content:
      "Il nostro team di professionisti, iscritti al registro dei revisori contabili presso il Ministero dell'Economia e delle Finanze, offre servizi di revisione contabile che includono: Revisione legale di società e gruppi industriali, Revisione volontaria di bilanci di esercizio e di bilanci consolidati, Reporting package integrato, Nomina di collegi sindacali.",
  },
];

const Template = () => {
  return (
    <Container>
      <div className={Style.areas}>
        <div className={Style.list}>
          <ItemList data={elements} />
        </div>

        <div className={Style.content}>
          <ContentList data={elements} />
        </div>
      </div>
    </Container>
  );
};

const ItemList = ({ data }) => {
  const [active, setActive] = useState(data[0].reference);

  return (
    <ul>
      {data.map(({ reference, title }, i) => (
        <li key={i} className={active === reference ? Style.active : undefined}>
          <a href={`#${reference}`} onClick={() => setActive(reference)}>
            {title}
          </a>
        </li>
      ))}
    </ul>
  );
};

const ContentList = ({ data }) => {
  return data.map(({ reference, content, title }, i) => (
    <div className={Style.description} key={i} id={reference}>
      <h3>{title}</h3>
      {content}
    </div>
  ));
};

export default Template;
