import React from "react";

// Libraries
import { Container, SimpleSlider } from "@hybris-software/ui-kit";

// Icons
import Icon1 from "../../../Assets/icons/service-icon-1.png";
import Icon2 from "../../../Assets/icons/service-icon-2.png";
import Icon3 from "../../../Assets/icons/service-icon-3.png";
import Icon4 from "../../../Assets/icons/service-icon-4.png";
import Icon5 from "../../../Assets/icons/service-icon-5.png";
import Icon6 from "../../../Assets/icons/service-icon-6.png";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

// Styles
import Style from "./ServicesGrid.module.css";

const cards = [
  {
    icon: Icon5,
    title: "Consulenza Aziendale",
    description:
      "Offriamo consulenza per operazioni societarie con esperienza e professionalità. Aiutiamo a eseguire valutazioni, preparare strategie e sfruttare le potenzialità dell'impresa con il supporto del personale.",
    link: "/",
  },
  {
    icon: Icon6,
    title: "Consulenza Fiscale",
    description:
      "Consulenza fiscale esperta copre tutti aspetti tassazione, strategie impresa e rappresentanza contenziosi. Pianificazione e consigli fiscali costantemente aggiornati.",
    link: "/",
  },
  // {
  //   icon: Icon4,
  //   title: "Consulenza Legale",
  //   description:
  //     "Studio legale specializzato in vertenze societarie, contrattuali, locazioni, famiglia e successione. Offriamo competenza, professionalità e riservatezza per soddisfare le esigenze dei nostri clienti in giudiziale e stragiudiziale.",
  //   link: "/",
  // },
  {
    icon: Icon1,
    title: "Consulenza Strategica",
    description:
      "Consulenza strategica a Milano con soluzioni personalizzate per le imprese. Siamo consulenti a 360 gradi, con competenze in comunicazione, problem solving e gestione del cambiamento. Affianchiamo gli imprenditori per raggiungere i loro obiettivi e rendere le loro aziende più forti e competitive. ",
    link: "/",
  },
  {
    icon: Icon2,
    title: "Consulenza Societaria",
    description:
      "Proponiamo soluzioni legali per costituzione e gestione di società. Offriamo assistenza in tutte le fasi, dalla predisposizione di strumenti di governance alla cessione e acquisizione di aziende. Con competenze civilistiche e commerciali, forniamo consulenza consapevole su costi e decisioni del processo.",
    link: "/",
  },
  {
    icon: Icon3,
    title: "Revisione Legale ",
    description:
      "Offriamo servizi di revisione legale per società e gruppi industriali. Revisione volontaria di bilancio, reporting integrato e Collegi Sindacali. I nostri revisori sono iscritti al registro presso il Ministero dell'Economia. Obbligatorietà prevista dalla L. 155/2017.",
    link: "/",
  },
];

const ServicesGrid = () => {
  return (
    <Container>
      <div className={Style.mainContainer}>
        <div className={Style.header}>
          <h6>in cosa siamo esperti</h6>
          <h3>I Nostri Servizi</h3>
        </div>
        <div className={Style.cardsGrid}>
          {cards.map((card, index) => {
            return (
              <ServiceCard
                key={index}
                icon={card.icon}
                title={card.title}
                description={card.description}
                link={card.link}
              />
            );
          })}
        </div>
        {window.innerWidth < 768 && (
          <div className={Style.cardsGridMobile}>
            <SimpleSlider
              animationDuration={400}
              autoPlay={false}
              disableDrag={false}
              slidePerView={1}
              paginationButtons={true}
              nextChild=">"
              prevChild="<"
              gap={50}
              paginationClass={Style.pagination}
            >
              {cards.map((card, index) => (
                <ServiceCard
                  key={index}
                  icon={card.icon}
                  title={card.title}
                  description={card.description}
                  link={card.link}
                />
              ))}
            </SimpleSlider>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ServicesGrid;

const ServiceCard = ({ icon, title, description, link }) => {
  return (
    <div className={Style.cardContainer}>
      <img alt="icon-1" src={icon} />
      <h4>{title}</h4>
      <p>{description}</p>
      <a href={link}>
        Scopri di più <HiOutlineArrowNarrowRight />
      </a>
    </div>
  );
};
