import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Constants
import teamMembers from "../../../Data/constants";

// Styles
import Style from "./TeamGrid.module.css";

const TeamGrid = () => {
  return (
    <Container>
      <div className={Style.teamGrid}>
        {teamMembers.map((item, index) => (
          <TeamCard key={index} item={item} />
        ))}
      </div>
    </Container>
  );
};

export default TeamGrid;

const TeamCard = ({ item }) => {
  return (
    <div className={Style.teamCard}>
      <img src={item.image} alt="team-card" />
      <div className={Style.cardInfo}>
        <h4>{item.name}</h4>
        <p>{item.role}</p>
        {item.expertizes.map((expertize, index) => (
          <p
            key={index}
            style={{
              textAlign: "center",
              fontSize: 14,
              color: "black",
              opacity: 0.7,
            }}
          >
            {expertize}
          </p>
        ))}
        {item.cv && (
          <a href={item.cv} target="_blank" rel="noreferrer">
            <h5>Curriculum</h5>
          </a>
        )}
      </div>
    </div>
  );
};
