import React from "react";

// Libraries
import { Container, Row, Col } from "@hybris-software/ui-kit";

// Styles
import Style from "./WhyChooseUs.module.css";

// Data
import { paths } from "../../../Data/routes";

const WhyChooseUs = () => {
 
  return (
    <Container>
      <div className={Style.mainContainer}>
        <div className={Style.header}>
          <h6>In cosa siamo Esperti</h6>
          <h3>Perché i nostri clienti scelgono noi?</h3>
        </div>
        <Row>
          <Col md={6} sm={12}>
            <p>
              I nostri clienti scelgono noi per la nostra competenza e
              professionalità nel campo della consulenza fiscale. Grazie alla
              nostra esperienza pluriennale, siamo in grado di offrire soluzioni
              efficaci e personalizzate per soddisfare le esigenze uniche di
              ogni cliente. Siamo sempre aggiornati sulle ultime novità
              legislative e fiscali per garantire ai nostri clienti un vantaggio
              competitivo sul mercato. Inoltre, siamo in grado di offrire una
              vasta gamma di servizi per soddisfare le esigenze di ogni tipo di
              attività commerciale, dalle piccole alle grandi aziende.
            </p>
          </Col>
          <Col md={6} sm={12}>
            <p>
              Il nostro team di professionisti altamente qualificati garantisce
              un servizio attento e preciso per garantire la massima
              soddisfazione del cliente. Siamo sempre disponibili per rispondere
              alle domande e fornire assistenza in qualsiasi momento. Inoltre,
              ci impegniamo a mantenere la massima trasparenza nei nostri
              processi e a garantire la sicurezza dei dati dei nostri clienti.
              Siamo orgogliosi di avere una lunga lista di clienti soddisfatti
              che ci scelgono per la qualità dei nostri servizi e per la fiducia
              che hanno in noi..
            </p>
          </Col>
        </Row>
        <div className={Style.counterContainer}>
          <div className={Style.counter}>
            <h4>150+</h4>
            <h6>Clienti soddisfatti</h6>
          </div>
          <div className={Style.counter}>
            {" "}
            <h4>16+</h4>
            <h6>Collaboratori</h6>
          </div>
          <div className={Style.counter}>
            {" "}
            <h4>15+</h4>
            <h6>Anni di Esperienza</h6>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default WhyChooseUs;
