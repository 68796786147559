// Images
import StefanoMarzoImg from "../Assets/images/stefanomarzo.jpg";
import SandroMarzoImg from "../Assets/images/sandromarzo.png";
import GianlucaMarzoImg from "../Assets/images/gianlucamarzo.jpg";
import StefanoCastelluzzoImg from "../Assets/images/stefanocastelluzzo.png";
import PlaceholderImg from "../Assets/images/team-placeholder.jpeg";
import RobertoVerdicchiaImg from "../Assets/images/robertoverdicchia.jpeg";

// CVs
import AntonioMarzoCV from "../Assets/files/cv_antoniomarzo.pdf";
import StefanoMarzoCV from "../Assets/files/cv_stefanomarzo.pdf";
import StefanoCastelluzzo from "../Assets/files/cv_stefanocastelluzzo.pdf";

const teamMembers = [
  {
    name: "Antonio Sandro Marzo",
    role: "Founder - Senior Partner",
    image: SandroMarzoImg,
    expertizes: ["Dott. Commercialista", "Revisore Legale"],
    cv: AntonioMarzoCV,
  },
  {
    name: "Stefano Marzo",
    role: "Co Founder - Senior Partner",
    image: StefanoMarzoImg,
    expertizes: ["Dott. Commercialista", "Revisore Legale"],
    cv: StefanoMarzoCV,
  },
  {
    name: "Roberto Verdicchia",
    role: "Co Founder - Senior Partner",
    image: RobertoVerdicchiaImg,
    expertizes: ["Dott. Commercialista"],
    cv: null,
  },
  {
    name: "Gianluca Marzo",
    role: "Co Founder - Senior Partner",
    image: GianlucaMarzoImg,
    expertizes: ["Dott. Commercialista", "Revisore Legale"],
    cv: null,
  },
  {
    name: "Stefano Castelluzzo",
    role: "Co Founder - Senior Partner",
    image: StefanoCastelluzzoImg,
    expertizes: ["Dott. Commercialista", "Revisore Legale"],
    cv: StefanoCastelluzzo,
  },
  {
    name: "Sergio Talesco",
    role: "Founder - Senior Partner",
    image: PlaceholderImg,
    expertizes: ["Dott. Commercialista", "Revisore Legale"],
    cv: null,
  },
];

export default teamMembers;
