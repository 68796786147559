import React from "react";

// Libraries
import { Container, SimpleSlider } from "@hybris-software/ui-kit";
import { Link } from "react-router-dom";

// Icons
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

// Constants
import teamMembers from "../../../Data/constants";

// Styles
import Style from "./TeamSlider.module.css";

// Data
import { paths } from "../../../Data/routes";

const standardsBreakpoints = {
  576: {
    slidesPerView: 1,
  },
  768: {
    slidesPerView: 2,
  },
  992: {
    slidesPerView: 2,
  },
  1200: {
    slidesPerView: 3,
  },
  1441: {
    slidesPerView: 3,
  },
};

const TeamSlider = () => {
  return (
    <Container className={Style.mainContainer}>
      <div className={Style.header}>
        <h3>Il Nostro Team</h3>
        <Link to={paths.main.team}>
          <h6>
            Vedi tutti <HiOutlineArrowNarrowRight />
          </h6>
        </Link>
      </div>
      <SimpleSlider
        animationDuration={400}
        autoPlay={false}
        disableDrag={false}
        slidePerView={3}
        paginationButtons={true}
        nextChild=">"
        prevChild="<"
        gap={50}
        breakPoints={standardsBreakpoints}
        paginationClass={Style.pagination}
      >
        {teamMembers.map((card, index) => (
          <TeamCard key={index} {...card} />
        ))}
      </SimpleSlider>
    </Container>
  );
};

export default TeamSlider;

const TeamCard = (card) => {
  return (
    <div className={Style.teamCard}>
      <div className={Style.imageContainer}>
        <img src={card.image} alt="" />
      </div>
      <div className={Style.info}>
        <h3>{card.name}</h3>
        {card.expertizes.map((expertize, index) => (
          <h6 key={index}>{expertize}</h6>
        ))}
      </div>
    </div>
  );
};
