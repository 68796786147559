import React, { useState } from "react";

// Components
import { Container } from "@hybris-software/ui-kit";

// Libraries
import { Button } from "@hybris-software/ui-kit";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// Icons
import {
  AiOutlinePhone,
  AiOutlineHome,
  AiOutlineMail,
  AiOutlineForm,
} from "react-icons/ai";

// Utils
import classNames from "../../../Utils/classNames";

// Data
import { navPaths } from "../../../Data/routes";

// Assets
import LogoWhite from "../../../Assets/logos/logo_mcv_bianco.png";

// Styles
import Style from "./TopNavBar.module.css";

const infoBoxData = [
  {
    icon: <AiOutlinePhone />,
    title: "Telefono",
    description: "0832 39 15 95",
  },
  {
    icon: <AiOutlineHome />,
    title: "Indirizzo",
    description: "Via Pitagora, 4, Lecce",
  },
  {
    icon: <AiOutlineMail />,
    title: "Email",
    description: "info@studiomcv.it",
  },
];

const TopNavbar = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <Container>
      <header>
        <div className={Style.topNavContainer}>
          <div className={Style.logoContainer} onClick={()=>{
            navigate("/");
          }}>
            <img src={LogoWhite} alt="logo" height={50} />
          </div>
          <div className={Style.infoBoxesContainer}>
            {infoBoxData.map((infoBox, index) => {
              return (
                <InfoBox
                  key={index}
                  icon={infoBox.icon}
                  title={infoBox.title}
                  description={infoBox.description}
                />
              );
            })}

            <Button
              basicClass={false}
              className={Style.headerButton}
              onClick={() => {
                navigate("/contacts");
              }}
            >
              <AiOutlineForm />
              <p>Contattaci</p>
            </Button>
            <div
              className={classNames(
                Style.navBarIconMobile,
                open && Style.active
              )}
              onClick={() => {
                setOpen(!open);
                if (!open) {
                  document.body.classList.add(Style.bodyScrollDisabled);
                } else {
                  document.body.classList.remove(Style.bodyScrollDisabled);
                }
              }}
            >
              <span className={Style.iconPartOne}></span>
              <span className={Style.iconPartTwo}></span>
              <span className={Style.iconPartThree}></span>
            </div>
          </div>
        </div>
        <MobileMenu location={location} open={open} setOpen={setOpen} />
      </header>
    </Container>
  );
};

export default TopNavbar;

const InfoBox = ({ icon, title, description }) => {
  return (
    <div className={Style.infoBox}>
      <div className={Style.iconContainer}>{icon}</div>
      <div className={Style.infoContainer}>
        <h5>{title}</h5>
        <p>{description}</p>
      </div>
    </div>
  );
};

const MobileMenu = ({ location, open, setOpen }) => {
  return (
    <div className={classNames(Style.mobileMenu, open && Style.mobileMenuOpen)}>
      <nav>
        <ul className={Style.navBarMenuMobile}>
          {navPaths.map((item, index) => {
            return (
              <li
                key={index}
                className={classNames(
                  location.pathname === item.path
                    ? Style.navBarMenuItemActive
                    : Style.navBarMenuItem
                )}
              >
                <Link
                  className={classNames(
                    Style.navBarMenuLink,
                    location.pathname === item.path &&
                      Style.navBarMenuLinkActive
                  )}
                  onClick={() => {
                    setOpen(false);
                    document.body.classList.remove(Style.bodyScrollDisabled);
                  }}
                  to={item.path}
                >
                  {item.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};
