import { Route } from "react-router-dom";

// Icons
import { AiFillHome } from "react-icons/ai";

// Layouts
import MainLayout from "../Layouts/MainLayout/MainLayout";

// Main Views
import HomeView from "../Views/Main/HomeView";
import AboutUsView from "../Views/Main/AboutUsView";
import ServicesView from "../Views/Main/ServicesView";
import TeamView from "../Views/Main/TeamView";
import ContactsView from "../Views/Main/ContactsView";

const routes = {
  main: {
    path: "/",
    element: <MainLayout />,
    title: "Insert title",
    routes: {
      home: {
        path: "",
        element: <HomeView />,
        title: "Home",
        icon: <AiFillHome />,
      },
      aboutUs: {
        path: "about-us",
        element: <AboutUsView />,
        title: "Su di noi",
        icon: <AiFillHome />,
      },
      services: {
        path: "services",
        element: <ServicesView />,
        title: "Servizi",
        icon: <AiFillHome />,
      },
      team: {
        path: "team",
        element: <TeamView />,
        title: "Team",
        icon: <AiFillHome />,
      },
      contacts: {
        path: "contacts",
        element: <ContactsView />,
        title: "Contatti",
        icon: <AiFillHome />,
      },
    },
  },
};

function generatePaths(routes, parentPath = "") {
  const paths = {};
  Object.entries(routes).forEach(([routeName, route]) => {
    if (route.routes) {
      paths[routeName] = generatePaths(route.routes, parentPath + route.path);
    } else {
      paths[routeName] = parentPath + route.path;
    }
  });
  return paths;
}

function generateRouteComponents(routes, parentPath = "") {
  if (parentPath === "") {
    // Is a layout
    return Object.keys(routes).map((routeName, index) => {
      return (
        <Route key={index} element={routes[routeName].element}>
          {generateRouteComponents(
            routes[routeName].routes,
            routes[routeName].path
          )}
        </Route>
      );
    });
  } else {
    // Is a view
    const views = [];

    Object.keys(routes).forEach((routeName, index) => {
      views.push(
        <Route
          key={index}
          path={parentPath + routes[routeName].path}
          element={routes[routeName].element}
        />
      );

      if (routes[routeName].routes) {
        views.push(
          generateRouteComponents(
            routes[routeName].routes,
            parentPath + routes[routeName].path
          )
        );
      }
    });
    return views;
  }
}

function generateNavBarPaths(routes) {
  const genRoutes = [];
  Object.entries(routes).forEach(([layoutName, layout]) => {
    Object.entries(layout.routes).forEach(([routeName, route]) => {
      if (!route.hideInNavbar) {
        genRoutes.push({
          name: route.title,
          path: route.path,
        });
      }
    });
  });
  return genRoutes;
}

const paths = generatePaths(routes);
const navPaths = generateNavBarPaths(routes);
const routeComponents = generateRouteComponents(routes);

export { navPaths, routes, paths, routeComponents };
