import React from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";

// Styles
import Style from "./HeroSection.module.css";

const HeroSection = () => {
  return (
    <div className={Style.heroSection}>
      <div className={Style.heroContainer}>
        <Container className={Style.heroContent}>
          <h1>I Nostri Servizi</h1>
          <p className={Style.subTitle}>
            I pilastri del nostro codice etico sono: soddisfazione cliente,
            perseveranza e attenzione. Co impegnamo a superare le aspettative
            con servizio di qualità.
          </p>
        </Container>
      </div>
    </div>
  );
};

export default HeroSection;
