import React from "react";

// Styles
// import Style from "./NotFoundView.module.css";

const NotFoundView = () => {
  return (
    <div>
      <h2>404</h2>
      <p>Page not found</p>
    </div>
  );
};

export default NotFoundView;
