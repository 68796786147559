import React from "react";

// Components
import { Container } from "@hybris-software/ui-kit";

// Libraries
import { useNavigate } from "react-router-dom";

// Data
import { navPaths } from "../../../Data/routes";

// Styles
import Style from "./Navbar.module.css";

const Navbar = () => {
  const navigate = useNavigate();

  document.addEventListener("scroll", () => {
    const nav = document.getElementsByClassName(Style.navBarContainer)[0];
    nav.classList.toggle(Style.navBarContainerIsPinned, window.scrollY >= 157);
  });

  return (
    <div id="sticky-nav" className={Style.navBarContainer}>
      <Container>
        <nav>
          <ul>
            {navPaths.map((path, index) => {
              return (
                <li
                  onClick={() => {
                    navigate(path.path);
                  }}
                  key={index}
                >
                  {path.name}
                </li>
              );
            })}
          </ul>
        </nav>
      </Container>
    </div>
  );
};

export default Navbar;
