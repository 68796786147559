import React from "react";

// Components
import NavBar from "../../Components/UIKit/Navbar/Navbar";
import TopNavbar from "../../Components/UIKit/TopNavBar/TopNavBar";
import Footer from "../../Components/UIKit/Footer/Footer";

// Libraries
import { Outlet } from "react-router-dom";

// Styles
// import Style from "./MainLayout.module.css";

const MainLayout = () => {
  return (
    <div>
      <TopNavbar />
      <NavBar />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;
