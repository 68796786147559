import React from "react";

// Libraries
import { Container, Row, Col } from "@hybris-software/ui-kit";

// Icons
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaLocationArrow } from "react-icons/fa";

// Styles
import Style from "./ContactInfo.module.css";

const ContactInfo = () => {
  return (
    <Container className={Style.mainContainer}>
      <Row>
        <Col md={4} sm={12}>
          <div className={Style.cardInfo}>
            <BsFillTelephoneFill />
            <h4>Telefono</h4>
            <p>I nostri collaboratori sono disponibili dalle 9 alle 18.</p>
            <a href="tel:0832391595">
              <h5>0832 39 15 95</h5>
            </a>
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div className={Style.cardInfo}>
            <MdEmail />
            <h4>Email</h4>
            <p>Inviaci una mail con i dettagli della tua richiesta.</p>
            <a href="mailto:info@studiomcv.it">
              <h5>info@studiomcv.it</h5>
            </a>
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div className={Style.cardInfo}>
            <FaLocationArrow />
            <h4>Posizione</h4>
            <p>Via Pitagora 4, Lecce, 73100</p>
            <a
              href="https://www.google.com/maps/place/Via+Pitagora,+4,+73100+Lecce+LE,+Italia/@40.3531153,18.1890669,17z/data=!3m1!4b1!4m5!3m4!1s0x13442e57cc5a3cf9:0x15608420d7020f72!8m2!3d40.3531112!4d18.1912556"
              target="_blank"
              rel="noreferrer"
            >
              <h5>0832 39 15 95</h5>
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactInfo;
