import React, { useEffect } from "react";

// Componenets
import HeroSection from "../../../Components/Advanced/HeroSectionHome/HeroSection";
import ServicesGrid from "../../../Components/Advanced/ServicesGrid/ServicesGrid";
import TeamSlider from "../../../Components/Advanced/TeamSlider/TeamSlider";
import AboutUsRow from "../../../Components/Advanced/AboutUsRow/AboutUsRow";
import ContactForm from "../../../Components/Advanced/ContactForm/ContactForm";
import Loader from "../../../Components/UIKit/Loader/Loader";

// Styles
import Style from "./HomeView.module.css";

const HomeView = () => {
  useEffect(() => {
    document.title = "Studio MCV | Home";
  }, []);
  return (
    <>
      <Loader />
      <section className={Style.heroSection}>
        <HeroSection />
      </section>

      <section className={Style.servicesGrid}>
        <ServicesGrid />
      </section>

      <section className={Style.teamSlider}>
        <TeamSlider />
      </section>

      <section className={Style.aboutUsRow}>
        <AboutUsRow />
      </section>

      <section className={Style.contactForm}>
        <ContactForm description="Compila il form di contatto e richiedi la tua consulenza, i nostri professionisti ti ricontatteranno entro 24 ore" />
      </section>
    </>
  );
};

export default HomeView;
