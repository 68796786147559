import React, { useEffect } from "react";

// Components
import Template from "../../../Components/Advanced/Template/Template";
import HeroSection from "../../../Components/Advanced/HeroSectionService/HeroSection";
import Loader from "../../../Components/UIKit/Loader/Loader";

// Styles
import Style from "./ServicesView.module.css";

const ServicesView = () => {

  useEffect(() => {
    document.title = "Studio MCV | Servizi";
  }, []);

  return (
    <div style={{ backgroundColor: "#e9e9e9" }}>
      <Loader />
      <section className={Style.heroSection}>
        <HeroSection />
      </section>
      <section className={Style.services}>
        <Template />
      </section>
    </div>
  );
};

export default ServicesView;
