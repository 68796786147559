import React, { useState } from "react";

// Libraries
import {
  Container,
  Row,
  Col,
  InputField,
  Button,
} from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";
import useForm from "@hybris-software/use-ful-form";

// Styles
import Style from "./ContactForm.module.css";

const ContactForm = ({
  style,
  title = "Richiedi una Consulenza",
  description = "Law is complicate matter. It can cause you a big problem if youignore it. Let us help you!",
}) => {
  const [buttonText, setButtonText] = useState("Invia");

  const contactFormApi = useQuery({
    url: "contactform/",
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      console.log(response);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const form = useForm({
    inputs: {
      firstName: {
        required: true,
      },
      lastName: {
        required: true,
      },
      email: {
        nature: "email",
        required: true,
      },
      phone: {
        formatter: (value) => {
          return value.replace(/[^+0-9]/g, "");
        },
      },
      message: {
        required: true,
      },
    },
  });

  return (
    <Container className={Style.containerForm} style={style}>
      <div className={Style.mainContainer}>
        <div className={Style.header}>
          <h3>{title}</h3>
          <h6>{description}</h6>
        </div>
        {!contactFormApi.isSuccess ? (
          <Row
            columnGap={{
              horizontal: {
                xs: 10,
                sm: 10,
                md: 10,
                lg: 20,
                xl: 20,
                xxl: 20,
              },
              vertical: {
                xs: 10,
                sm: 10,
                md: 10,
                lg: 10,
                xl: 10,
                xxl: 10,
              },
            }}
          >
            <Col md={6} sm={12}>
              <InputField
                placeholder="Name*"
                {...form.getInputProps("firstName")}
              />
            </Col>
            <Col md={6} sm={12}>
              <InputField
                placeholder="Cognome*"
                {...form.getInputProps("lastName")}
              />
            </Col>
            <Col md={6} sm={12}>
              <InputField
                placeholder="Email*"
                {...form.getInputProps("email")}
              />
            </Col>
            <Col md={6} sm={12}>
              <InputField
                placeholder="Telefono"
                {...form.getInputProps("phone")}
              />
            </Col>
            <Col sm={12}>
              <textarea
                className={Style.textArea}
                placeholder="Messaggio*"
                rows={8}
                maxLength={400}
                value={form.getInputProps("message").value}
                onChange={(event) => {
                  form.getInputProps("message").setValue(event.target.value);
                }}
              />
            </Col>
            <span
              onMouseOver={() => {
                if (!form.isValid()) {
                  setButtonText("Compila tutti i campi");
                }
              }}
              onMouseLeave={() => {
                setButtonText("Invia");
              }}
            >
              <Button
                className={Style.button}
                buttonClassName={Style.buttonEnabled}
                disabledClassName={Style.buttonDisabled}
                disabled={!form.isValid()}
                isLoading={contactFormApi.isLoading}
                onClick={() => {
                  contactFormApi.executeQuery(form.values);
                }}
              >
                {buttonText}
              </Button>
            </span>
          </Row>
        ) : (
          <div className={Style.animationCheckContainer}>
            <div className={Style.wrapper}>
              <svg
                className={Style.checkmark}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className={Style.checkmark__circle}
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className={Style.checkmark__check}
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
            <p>
              Abbiamo ricevuto la tua richiesta di contatto, ti risponderemo al
              più presto!
            </p>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ContactForm;
