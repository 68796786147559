import React, { useState, useRef, useEffect } from "react";

// Components
import { Container } from "@hybris-software/ui-kit";

// Icons
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";

// Assets
import FaqImage from "../../../Assets/images/background-faq.jpg";

// Styles
import Style from "./Faq.module.css";

const faqs = [
  {
    title: "Come posso prenotare una consulenza con voi?",
    description:
      "Per prenotare una consulenza con lo studio, è possibile contattarci telefonicamente al numero 0832 39 15 95, inviare una email a info@studiomcv.it o compilare il form di contatto che trovi sotto alla fine di questa pagina. Sarà nostra premura fornire tutte le informazioni necessarie e fissare un appuntamento in data e ora convenienti per voi. Non esitate a contattarci per qualsiasi ulteriore informazione o chiarimento.",
  },
  {
    title: "Quali sono i vostri orari di apertura?",
    description:
      "Lo studio è aperto dal lunedì al venerdì, dalle 09:00 alle 18:00. Si prega di contattarci per verificare la disponibilità in caso di richiesta di appuntamento fuori dagli orari di apertura standard.",
  },
  {
    title: "Dove si trova il vostro studio?",
    description:
      "Lo studio si trova in Via Pitagora numero 4 a Lecce. Siamo facilmente raggiungibili con i mezzi pubblici e vi è ampio spazio per il posteggio auto.",
  },
  {
    title: "Offrite consulenza per apertura partite iva?",
    description:
      "Sì, offriamo consulenza per l'apertura di partite IVA sia per privati che per imprese. Siamo in grado di fornire supporto nella scelta della forma giuridica più adatta, nella redazione di business plan e nell'assistenza nella compilazione della documentazione necessaria per l'apertura della partita IVA. Non esitate a contattarci per ulteriori informazioni o per fissare un appuntamento.",
  },
  {
    title: "Offrite consulenza per aprire società?",
    description:
      "Sì, offriamo consulenza completa per l'apertura di società. Aiutiamo i nostri clienti nella scelta della forma giuridica più adatta, nella stesura del business plan, nella preparazione della documentazione necessaria e nella registrazione presso le autorità competenti. Siamo sempre a disposizione per ulteriori informazioni o per prenotare un appuntamento.",
  },
];

const Faq = () => {
  return (
    <Container
      className={Style.mainContainer}
      style={{
        backgroundImage: `url(${FaqImage})`,
      }}
    >
      <h2>Domande Frequenti</h2>
      {faqs.map((faq, index) => (
        <FaqItem key={index} title={faq.title} description={faq.description} />
      ))}
    </Container>
  );
};

const FaqItem = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = isOpen
      ? `${contentRef.current.scrollHeight}px`
      : "0px";
  }, [contentRef, isOpen]);

  return (
    <div className={Style.faqItem}>
      <h3
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className={Style.titleContainer}>
          {isOpen ? <AiOutlineMinus /> : <AiOutlinePlus />}

          <span style={{ marginLeft: 10 }}>{title}</span>
        </div>
      </h3>
      <p ref={contentRef} className={Style.faqDesc}>
        {description}
      </p>
    </div>
  );
};

export default Faq;
