import React, { useEffect } from "react";

// Components
import HeroSection from "../../../Components/Advanced/HeroSectionTeam/HeroSection";
import TeamGrid from "../../../Components/Advanced/TeamGrid/TeamGrid";
import ContactForm from "../../../Components/Advanced/ContactForm/ContactForm";
import Loader from "../../../Components/UIKit/Loader/Loader";

// Styles
import Style from "./TeamView.module.css";

const TeamView = () => {
  useEffect(() => {
    document.title = "Studio MCV | Team";
  }, []);
  return (
    <div style={{ backgroundColor: "#e9e9e9" }}>
      <Loader />
      <section className={Style.heroSection}>
        <HeroSection />
      </section>
      <section className={Style.teamGrid}>
        <TeamGrid />
      </section>
      <section className={Style.contactForm}>
        <ContactForm
          style={{
            backgroundColor: "transparent",
          }}
          title="Invia la tua candidatura"
          description="Vorresti far parte del nostro team di esperti? Compila il form ed inviaci il tuo curriculum, un nostro collaboratore analizzerà la tua richiesta"
        />
      </section>
    </div>
  );
};

export default TeamView;
