import React from "react";

// Libraries
import { Container, Row, Col } from "@hybris-software/ui-kit";
import { useNavigate } from "react-router-dom";

// Images
import Logo from "../../../Assets/logos/logo_mcv_bianco.png";
import Logo24 from "../../../Assets/logos/logo-sole-24-ore.png";

// Styles
import Style from "./Footer.module.css";

// Data
import { navPaths } from "../../../Data/routes";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className={Style.mainContainer}>
      <Container className={Style.footerContainer}>
        <Row>
          <Col md={4} sm={12}>
            <div className={Style.logoColumn}>
              <img src={Logo} alt="Logo" />
              <p>
                Società tra Professionisti Marzo Castelluzzo Verdicchia. Esperti
                in Consulenza a Lecce. Consulenza amministrativa, Consulenza
                societaria, Consulenza tributaria.
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Partner{" "}
                  <a
                    href="https://www.ilsole24ore.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{ marginLeft: 10, height: 50, width: "auto" }}
                      src={Logo24}
                    />
                  </a>
                </div>
              </p>
              <p>Partita IVA 03254490752</p>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className={Style.contactInfo}>
              <h4>Contatti</h4>
              <ul>
                <li>
                  <span>Sede Lecce:</span> Via Pitagora, 4, Lecce
                </li>
                <li>
                  <span>Sede Milano:</span> Via Balilla, 6, Milano
                </li>
                <li>
                  <span>Telefono:</span> 0832 39 15 95
                </li>
                <li>
                  <span>Fax:</span> 0832 39 15 95
                </li>
                <li>
                  <span>Email:</span> info@studiomcv.it
                </li>
              </ul>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className={Style.siteMapColumn}>
              <h4>Sitemap</h4>
              <ul>
                {navPaths.map((path, index) => {
                  return (
                    <li
                      onClick={() => {
                        navigate(path.path);
                      }}
                      key={index}
                    >
                      <span>{path.name}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={Style.bottomFooter}>
        <p>
          Studio MCV - Website developed by
          <a
            rel="noreferrer"
            style={{ marginLeft: 4 }}
            href="https://hybrissoftware.it"
            target="_blank"
          >
            Hybris Software
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
