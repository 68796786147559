import React, { useState, useEffect } from "react";

// Styles
import Style from "./Loader.module.css";

const Loader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.body.classList.add(Style.bodyScrollDisabled);

    setTimeout(() => {
      document.body.classList.remove(Style.bodyScrollDisabled);
      setLoading(false);
    }, 900);
  }, []);

  return (
    <>
      {loading && (
        <div className={Style.loaderContainer}>
          <span className={Style.loader}></span>
        </div>
      )}
    </>
  );
};

export default Loader;
