import React, { useEffect } from "react";

// Components
import HeroSection from "../../../Components/Advanced/HeroSectionContact/HeroSection";
import ContactForm from "../../../Components/Advanced/ContactForm/ContactForm";
import ContactInfo from "../../../Components/Advanced/ContactInfo/ContactInfo";
import MapBox from "../../../Components/Advanced/MapBox/MapBox";
import Loader from "../../../Components/UIKit/Loader/Loader";

// Styles
import Style from "./ContactsView.module.css";

const ContactsView = () => {
  useEffect(() => {
    document.title = "Studio MCV | Contatti";
  }, []);
  return (
    <>
      <Loader />
      <section className={Style.heroSection}>
        <HeroSection />
      </section>

      <section className={Style.contactInfo}>
        <ContactInfo />
      </section>

      <section className={Style.mapBox}>
        <MapBox />
      </section>

      <section className={Style.contactForm}>
        <ContactForm
          style={{
            backgroundColor: "#f5f5f5",
            paddingBottom: 100,
          }}
          title="Contattaci"
          description="Compila il form di contatto e richiedi la tua consulenza, i nostri professionisti ti ricontatteranno entro 24 ore"
        />
      </section>
    </>
  );
};

export default ContactsView;
